<template>
  <span>
    <delete-snackbar
      :snackbar_remove="snackbar"
      v-on:snackbar="snackbar = $event"
      :tmp_remove_program="tmp_remove_program"
    />
    <clone-dialog
      :snackbar_clone="snackbar_clone"
      v-on:snackbar_clone="snackbar_clone = $event; "
      :tmp_clone_program="tmp_clone_program"
    />

    <v-dialog @keydown.esc="cancel()" v-model="dialog" persistent max-width="60%" min-height="90%">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-3"
          small
          outlined
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >{{ $t("program.title") }}</v-btn>
      </template>

      <v-card class="pl-3 pr-3">
        <v-card-title class="text-capitalize">
          <v-row no-gutters>
            <v-col cols="12" sm="8">
              <h3 class="primary--text text-capitalize">
                <!-- <v-icon color="primary" class="mr-1" small>mdi-food-apple</v-icon> -->
                <span class="app-name ma-0 text--secondary">{{$t("program.title")}}</span>
              </h3>
            </v-col>
            <v-col>
              <div class="text-right mt-2">
                <v-btn
                  class="rem-06"
                  outlined
                  small
                  color="primary lighten-1"
                  @click="preclone_program()"
                >+ {{$t('program.clone')}}</v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-card class="pt-1" elevation="0">
            <div style="overflow-y: scroll" class="pa-2">
              <v-row no-gutters class="mb-3">
                <v-col cols="12" sm="1" align-self="center" class="text-right">
                  <v-icon small class="mr-3">mdi-clipboard-text-search-outline</v-icon>
                </v-col>

                <v-col sm="11">
                  <v-autocomplete
                    class="messages-off"
                    style="font-weight: 500"
                    :label="$t('product.filter')"
                    v-model="product_selected"
                    :items="items"
                    item-text="text"
                    item-value="id"
                    :placeholder="$t('product.search')"
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" sm="1" align-self="center" class="text-right">
                  <v-icon small class="mr-3">mdi-magnify</v-icon>
                </v-col>
                <v-col sm="11">
                  <v-autocomplete
                    class="messages-off"
                    outlined
                    dense
                    chips
                    small-chips
                    :label="$t('program.filter')"
                    v-model="search"
                    :items="filter_by_product()"
                    :placeholder="$t('program.search')"
                    clearable
                    item-text="title"
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" sm="1" align-self="center" class="text-right">
                  <v-icon small class="mr-3">mdi-filter-outline</v-icon>
                </v-col>
                <v-col sm="11">
                  <v-chip-group
                    column
                    mandatory
                    v-model="filter_selection"
                    active-class="primary--text"
                  >
                    <v-chip small v-for="tag in tags" :key="tag">{{tag}}</v-chip>
                  </v-chip-group>
                </v-col>
              </v-row>
            </div>
            <!-- <hr>  -->
            <v-radio-group class="pt-4 modal-list-border" v-model="program_value" mandatory>
              <div class="modal-list">
                <v-row
                  no-gutters
                  v-for="program in filter(programs)"
                  :key="program.title"
                  style="border-bottom: 1px solid #edebe9"
                  class="ma-2 pb-1"
                >
                  <v-col cols="12" sm="6" v-if="program">
                    <v-radio class="rem-08 pl-1" :label="program.title" :value="program.id"></v-radio>
                  </v-col>
                  <v-col cols="12" sm="3" v-if="program">
                    <small>{{get_object_category(program)}}</small>
                  </v-col>
                  <v-col cols="12" sm="3" class="text-right" v-if="current_program">
                    <small
                      class="ma-3"
                      v-if="current_program.id == program.id"
                    >{{$t('program.current')}}</small>

                    <!-- <v-btn icon @click="edit_program()">
                      <v-icon class="rem-1" color="#a8a8a8">mdi-pencil-outline</v-icon>
                    </v-btn>-->

                    <v-icon
                      v-if="!program.immutable && current_program.id != program.id"
                      @click="predelete_program(program)"
                      class="rem-1"
                      color="#a8a8a8"
                    >mdi-delete-outline</v-icon>
                  </v-col>
                </v-row>
              </div>
            </v-radio-group>
          </v-card>
        </v-card-text>

        <v-card-actions class="pa-5 mt-4">
          <v-btn color="gray darken-1" text @click="cancel">{{$t('cancel')}} (esc)</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="pa-2"
            @click="save"
            :disabled="current_program ? current_program.id == program_value : false"
          >
            <span class="pa-2">{{$t('program.switch')}}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import { cloneDeep } from "lodash";
import { mapState, mapActions } from "vuex";
import cloneDialog from "./cloneDialog";

import deleteSnackbar from "./deleteSnackbar";
export default {
  name: "selectProgram",
  data() {
    return {
      product_selected: "",
      program_value: "",
      filter_selection: "",
      search: "",
      dialog: false,
      snackbar: false,
      snackbar_clone: false,
      tmp_remove_program: undefined,
      tmp_clone_program: undefined
    };
  },
  components: { cloneDialog, deleteSnackbar },
  computed: {
    ...mapState({
      programs: state => state.available_programs || undefined,
      current_program: state => state.program
    }),
    tmp_programs() {
      return cloneDeep(this.programs);
    },
    items() {
      return this.$store.getters.availableProducts.map(entry => {
        return {
          text: this.$t("proto." + entry.title),
          id: entry.id
        };
      });
    },
    tags() {
      return [this.$t("program.sort_asc"), this.$t("program.sort_desc")];
    }
  },
  watch: {
    snackbar_clone(v) {
     this.dialog= false;
    },
    filter_selection(val) {
      if (val == 0) {
        this.tmp_programs.sort((a, b) => {
          return a.title > b.title ? 1 : -1;
        });
      } else {
        this.tmp_programs.sort((a, b) => {
          return a.title > b.title ? -1 : 1;
        });
      }
    },
    dialog(v) {
      this.product_selected = "";
      this.filter_selection = "";
      this.search = "";
      this.program_value = this.current_program ? this.current_program.id : -1;
    }
  },
  methods: {
    ...mapActions([
      "change_active_program_id",
      "delete_program",
      "store_tmp_droppingRelationsList"
    ]),
    get_object_category(_program) {
      if (_program.objectCategoriesList.length > 0) {
        let l = this.$store.getters.availableProducts.filter(
          x => x.id == _program.objectCategoriesList[0]
        );
        if (l.length > 0) {
          return this.$t("proto." + l[0].title);
        }
      }
      return _program.objectCategoriesList;
    },
    edit_program() {},

    preclone_program() {
      this.snackbar_clone = true;
      this.tmp_clone_program = this.programs.filter(
        x => x.id == this.program_value
      )[0];
    },

    predelete_program(program) {
      this.snackbar = true;
      this.tmp_remove_program = program;
    },

    save() {
      console.log("change active program", this.program_value);
      this.change_active_program_id(this.program_value);
      this.store_tmp_droppingRelationsList([]);
      this.dialog = false;
    },

    cancel() {
      this.dialog = false;
    },

    filter_by_product() {
      if (parseInt(this.product_selected)) {
        return this.tmp_programs.filter(p =>
          p.objectCategoriesList.includes(parseInt(this.product_selected))
        );
      }
      return this.tmp_programs;
    },

    filter() {
      let s = this.search || "";
      if (s.length > 0) {
        return this.filter_by_product().filter(p => p.title.includes(s));
      }
      return this.filter_by_product();
    }
  }
};
</script>

<style>
.v-list-item__title {
  font-size: 0.8rem !important;
}
input {
  font-size: 0.8rem !important;
}
label {
  font-size: 0.8rem !important;
}
.modal-list-border {
  /* border-right: 1px solid #f1f6ff;
  border-left: 1px solid #f1f6ff; */
}
.modal-list {
  font-size: 0.8rem !important;
  max-height: 25vh !important;
  min-height: 25vh !important;
  overflow-y: scroll;
}
.rem-06 {
  font-size: 0.6rem !important;
}
.rem-08 {
  font-size: 0.8rem !important;
}
.rem-1 {
  font-size: 1rem !important;
}
</style>
