<template>
  <v-row v-if="statistics != undefined">
    <v-col cols="12" :sm="sm">
      <v-card class="mx-auto" color="#f79936" elevation="0">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              <b v-if="statistics.hasOwnProperty('capacity')">{{statistics.capacity.toFixed(1)}}</b>
              <small class="ml-3 my-text-overline">ton/h</small>
            </v-list-item-title>
            <v-list-item-subtitle class="my-text-overline">{{$t("dashboard.capacity")}}</v-list-item-subtitle>
          </v-list-item-content>
          <v-icon>mdi-archive-clock-outline</v-icon>
        </v-list-item>
      </v-card>
    </v-col>

    <v-col cols="12" :sm="sm">
      <v-card class="mx-auto" color="#4e4c4c" elevation="0">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="text-h6 white--text">
              <b v-if="statistics.totalProducts">{{statistics.totalProducts}}</b>
            </v-list-item-title>
            <v-list-item-subtitle
              class="white--text my-text-overline"
            >{{$t("dashboard.total_products")}}</v-list-item-subtitle>
          </v-list-item-content>

          <v-icon color="white">mdi-food-apple-outline</v-icon>
        </v-list-item>
      </v-card>
    </v-col>

    <v-col cols="12" :sm="sm">
      <v-card class="mx-auto" color="#4e4c4c" elevation="0">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="text-h6 white--text">
              <b v-if="statistics.totalWeight">{{statistics.totalWeight.toFixed(2)}}</b>
              <small class="ml-3 white--text my-text-overline">kg</small>
            </v-list-item-title>
            <v-list-item-subtitle
              class="white--text my-text-overline"
            >{{$t("dashboard.total_weight")}}</v-list-item-subtitle>
          </v-list-item-content>
          <v-icon color="white">mdi-scale</v-icon>
        </v-list-item>
      </v-card>
    </v-col>

    <v-col cols="12" :sm="sm">
      <v-card class="mx-auto" color="#f79936" elevation="0">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="text-h6 mb-1">
              <b v-if="statistics.avgProductWeight">{{statistics.avgProductWeight}}</b>
                <!-- <b v-if="statistics.fillRate">{{statistics.fillRate}}</b> -->
              <small class="ml-3 my-text-overline"></small>
            </v-list-item-title>
            <v-list-item-subtitle class="my-text-overline">{{$t("dashboard.fill_rate")}}</v-list-item-subtitle>
          </v-list-item-content>
          <v-icon>mdi-basket-fill</v-icon>
        </v-list-item>
      </v-card>
    </v-col>
  </v-row>
</template>


<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "mainMetrics",
  data: () => ({}),
  props:{
    md:{
      type:Number
    }
  },
  computed: {
    ...mapState({
      statistics: state => state.statistics
    }),
    sm(){
      if( this.md == 7){return 3}
      return 12;
    }
  }
};
</script>

<style>
.my-text-overline {
  font-size: 0.75rem !important;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
  line-height: 0rem;
  letter-spacing: 0.066666667em !important;
  text-transform: uppercase !important;
}
</style>
