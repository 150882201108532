


























































import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { cloneDeep } from "lodash";

import { CupObject } from "gs-proto/js/gs/proto/ui_data_pb";
import { State } from "@/store/model";

export default {
  data() {
    return {};
  },
  props: {
    cup_active: {}
  },
  computed: {
    ...mapState({
      current_program: state => (state as State).program
    })
  },
  methods: {
    range(size) {
      return [...Array(size).keys()].map(i => i);
    }
  }
};
