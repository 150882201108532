







































































































































import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { cloneDeep } from "lodash";

import { CupObject } from "gs-proto/js/gs/proto/ui_data_pb";
import {
  fields_meta,
  ObjectProfileMetaType
} from "gs-proto/js/gs/proto/categories_data_meta";
import { ObjectProfile } from "gs-proto/js/gs/proto/object_data_pb";
import { VisionConfiguration } from "gs-proto/js/gs/proto/configs_data_pb";
import { State } from "@/store/model";

import ColorResults from "./ColorResults.vue";

export default {
  data() {
    return {
      fields_meta,
      avg_color: undefined
    };
  },
  props: {
    cup_active: {}
  },
  components: {
    ColorResults
  },
  computed: {
    ...mapState({
      current_program: state => (state as State).program
    })
  },
  watch: {
    cup_active() {
      let avg_field = (this as any).cup_active.objectProfile.filedValuesList.filter(
        x => x.field == 40001
      );
      if (avg_field.length > 0) {
        (this as any).avg_color = avg_field[0].colorArray.colorsList[0];
      }
    }
  },

  methods: {
    total_surface(filedValue) {
      let all_surfaces = filedValue.colorArray.colorsList.map(
        x => x.surface * 100
      );
      const reducer = (accumulator, curr) => accumulator + curr;
      return all_surfaces.reduce(reducer);
    },
    renderOneColor(_color) {
      let color = cloneDeep(_color);

      if (color.hue < 0.5) {
        color.hue += 0.5;
      } else {
        color.hue -= 0.5;
      }

      let style =
        "background:  hsl(" +
        color.hue * 360 +
        "," +
        color.saturation * 100 +
        "%, " +
        color.value * 100 +
        "%)";
      return style;
    },

    get_object_title(objectCategory) {
      let o_list = (this as any).$store.getters.availableProducts.filter(
        x => x.id == objectCategory
      );
      if (o_list.length > 0) {
        return (
          objectCategory + "-" + (this as any).$t("proto." + o_list[0].title)
        );
      }
      return objectCategory;
    },

    format_timestamp(secs) {
      let t = new Date(Date.UTC(1970, 0, 1)); // Epoch
      t.setUTCSeconds(secs);
      return t;
    },

    get_field_name(field) {
      let f_list = (this as any).$store.getters.availableFields.filter(
        x => x.id == field
      );
      if (f_list.length > 0) {
        return field + "-" + (this as any).$t("proto." + f_list[0].title);
      }
      return field;
    },
    blush_active(field) {
      let class_id = (this as any).cup_active.classId;
      let all_criteria = (this as any).current_program.objectCategoryProgramsList[0].classesList.filter(
        x => x.id == class_id
      )[0].criteraList;
      return (
        all_criteria.filter(cr => cr.field == field)[0].colorSurfaceRange
          .maxSurface > 0
      );
    }
  }
};
