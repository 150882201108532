





















import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { cloneDeep } from "lodash";

import { MachineComponentCategory} from "gs-proto/js/gs/proto/categories_data_pb";
import { MachineComponent } from "gs-proto/js/gs/proto/machine_data_pb";
import { machine_component_categories_meta} from "gs-proto/js/gs/proto/categories_data_meta";

import { State } from "@/store/model";


export default Vue.extend({
  data: () => ({
    component_belt_category: 0, // by default
    machine_component_categories_meta,
  }),
  mounted(){
    this.machine_component_categories_meta.forEach(x=> {
      if (x.title=='machine-speed'){
        this.component_belt_category = x.id;
      }
    })
  },
 
  computed: {
    ...mapState({
      machineComponents:  state => (state as State).machine?.componentsList as MachineComponent.AsObject[]
    }),
    machine_speed(){
      if( (this.machineComponents) && (this.machineComponents.filter(x=>x.category == this.component_belt_category).length > 0)){
        return this.machineComponents.filter(x=>x.category ==  this.component_belt_category)[0].pb_double.toFixed(2);
      } else {
        return "";
      }
    }
  },
});
