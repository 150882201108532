<template>
  <div class="mt-2">
    <!-- Overlay messages depending of the machine state -->
    <states-msg ref="statesComponent"/>

    <v-toolbar color="transparent" extended extension-height="5" elevation="0">
      <v-toolbar-title class="ml-1 mt-5">
        <div class="pa-2">
          <v-row>
            <v-col cols="12" sm="8">
              <!-- program selected -->
              <small class="program-title " v-if="current_program">
                <b>{{ current_program && current_program.title }}</b>
              </small>

              <!-- program not selected yet -->
              <small v-else>
                <v-icon color="red" class="mr-2 mb-1">mdi-alert</v-icon>
                <span class="red--text program-title">{{$t("dashboard.no_program")}}</span>
              </small>
           
              <select-program />
            </v-col>
          </v-row>

          <!-- state -->
          <v-row no-gutters class="mt-2">
            <v-col cols="12" sm="6">
              <span class="text-overline-sm mt-2">  {{$t("dashboard.machine_state")}}:</span>
              <v-btn
                class="ml-3"
                small
                text
                @click="open_overlay"
              >
                {{ $store.getters.statusSummary.state }}
                <v-icon
                  x-small
                  class="ml-2"
                  :color="$store.getters.statusSummary.color"
                >{{ $store.getters.statusSummary.icon }}</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6"></v-col>
          </v-row>
        </div>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      
      <!-- calibration -->
      <v-btn v-if="!calibration_state" rounded small color="primary" outlined class="mr-3 " @click="calibrate">
        <v-icon x-small color="primary" class="mr-2">mdi-cup-outline</v-icon>Calibrate
      </v-btn>

      <!-- log -->
      <v-dialog v-model="log_dialog" width="100%">
        <template v-slot:activator="{ on, attrs }">
         <v-btn rounded small
            :color="$store.getters.statusSummary.color"
            outlined
            class=" mr-3"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon
              small
              :color="$store.getters.statusSummary.color"
              class="mr-2"
              v-on="on"
            >{{ $store.getters.statusSummary.icon }}</v-icon>LOG
          </v-btn>
        </template>

        <timeline-log :height="90"/>
      </v-dialog>
    
      <!-- results -->
      <results-dialog class="m-10"/>
      
      <!-- language -->
      <v-select
        class="messages-off select-right overline ml-4"
        v-model="$i18n.locale"
        :items="languages"
        text-item="value"
        color="white"
      ></v-select>

    </v-toolbar>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import StatesMsg from "@/components/dashboard/StatesMsg";
import SelectProgram from "../program/selectProgram";
import ResultsDialog from "../results/ResultsDialog";
import TimelineLog from "@/components/TimelineLog";
import OverlayMsg from "@/components/OverlayMsg";
export default {
  name: "dashboardToolBar",

  components: {
    StatesMsg,
    ResultsDialog,
    SelectProgram,
    TimelineLog,
    OverlayMsg
  },
  data: () => ({
    log_dialog: false,
    languages: [{ value: "en", text: "🇬🇧 EN" }, { value: "nl", text: "🇳🇱 NL" }]
  }),
  computed: {
    ...mapState({
      available_programs: state => state.available_programs || [],
      current_program: state => state.program
    }),
    
    calibration_state(){
        return this.$store.getters.statusSummary.state == 'VISION_CALIBRATION' || 
                this.$store.getters.statusSummary.state == 'LOADCELL_CALIBRATION'
    }
  },
  methods: {
    ...mapActions(["set_calibration_mode"]),
    calibrate() {
      this.set_calibration_mode();
    },
    open_overlay() {
      let states_c = this.$refs.statesComponent;
      if (states_c) {
        states_c.force_overlay();
      }
    }
  }
};
</script>

<style>
.text-overline-sm {
    font-size: 0.75rem !important;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0.1666666667em !important;
    font-family: "Roboto", sans-serif !important;
    text-transform: uppercase !important;
}
.program-title{
  font-size: 1.4rem !important;
}
</style>