<template>
  <v-card dense :height="height+'vh'">
    <v-card-title class="pa-2 lighten-3 text-center">
      <v-icon class="ml-10">mdi-car-brake-alert</v-icon>
      <h3 class="text-h6 font-weight-light grow">{{$t('timelog')}}</h3>
    </v-card-title>
    <v-card-text dense class="py-0">
      <v-timeline
        dense
        clipped
        class="overflow-x"
        :style="'max-height: '+(height -10) +'vh !important;'"
      >
        <v-slide-x-reverse-transition group hide-on-leave>
          <v-timeline-item
            v-for="(s,i) in  $store.state.reply_commons.problemsList"
            :key="i"
            :color="machine_problems[s.type].color"
          >
            <v-card
              :style="'border: 1px solid ' + machine_problems[s.type].color"
              dense
              :value="true"
              class="pa-2"
            >
              <b>
                {{s.description}}
                <v-chip
                  x-small
                  :color="machine_problems[s.type].color"
                  class="ml-2 mb-1 text-uppercase white--text"
                >{{ machine_problems[s.type].type}}</v-chip>
              </b>
            </v-card>
          </v-timeline-item>
          <v-timeline-item
            v-for="(item,key) in $store.state.problemsQueue.slice().reverse().filter(x=> !is_active(x,  $store.state.reply_commons.problemsList))"
            :key="'1_'+ key"
            small
            :color="machine_problems[item.type].color || 'primary'"
            style="opacity:0.8"
          >
            <v-card :color="'#55555'" dense :value="true">
              <span class="mr-2 ml-2">{{item.description}}</span>
              <v-chip x-small class="ml-2 mb-1 text-uppercase">{{$t('solved')}}</v-chip>
            </v-card>
          </v-timeline-item>
        </v-slide-x-reverse-transition>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>



<script>
export default {
  name: "TimelineLog",

  computed: {
    machine_problems() {
      return this.$store.getters.machineProblemsTypes;
    }
  },
  props: {
    height: {
      type: Number,
      default: 45
    }
  },

  methods: {
    is_active(value, y) {
      if (y.length > 0) {
        let _value = JSON.stringify(value);
        for (let key in y) {
          let _i = JSON.stringify(y[key]);
          if (_i === _value) {
            return true;
          }
        }
      }
      return false;
    }
  }
};
</script>

<style>
.overflow-x {
  overflow: auto !important;
  /* overflow-x: scroll !important; */
  /* max-height: 38vh !important; */
}
</style>

