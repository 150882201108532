<template>
  <div>
    <!-- snackbar pile for notifications -->
    <v-snackbar
    class="mt-10"
      color="red"
      top
      right
      :style="{'margin-top':calcMargin(i)}"
      v-for="(s,i) in alerts"
      :key="i"
      v-model="show"
      :timeout="8000"
    >
      <v-icon v-if="s.level == 1" small class="mr-2">mdi-alert</v-icon>
      {{ s.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" @click="show = false" x-small icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <!-- snackbar pile for problemList -->
    <div v-if="new_problemsList &&  new_problemsList.length > 0">
      <v-snackbar
       class="mt-10"
        top
        right
        :style="{'margin-top':calcMargin(i)}"
        v-for="(s,i) in new_problemsList"
        :key="i"
        v-model="show_problem"
        :timeout="10000"
        :color="machine_problems[s.type].color"
      >
        <v-icon v-if="s.type < 3" small class="mr-2">mdi-alert</v-icon>
        <v-icon v-else small class="mr-2">mdi-information-outline</v-icon>
        {{ s.description }}
        <template v-slot:action="{ attrs }">
          <v-btn v-bind="attrs" @click="show_problem = false" x-small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>

    <!-- toolbar -->
    <dash-tool-bar/>

    <!-- main -->
    <v-container fill-height fluid no-gutter>
      <v-row no-gutter>
        <v-col cols="12" sm="12" :md="md" class="ma-0 pa-0">
          <v-card class="pa-5 mb-5 ml-5 background-transparent" elevation="0">
            <v-row v-if="md==7" no-gutters class="machine-schema">
              <machine-schema/>
            </v-row>
            <v-row no-gutters>
              <v-col class="ma-0 pa-0" cols="12" :sm="md==7 ? 6 : 12">
                <machine-speed/>
              </v-col>
              <v-col  v-if="md==7" class="ma-0 pa-0" cols="12" sm="6">
                <v-card
                  style="background:transparent;"
                  class="ma-0 pa-0"
                  width="100%"
                  elevation="0"
                  v-if="current_program"
                >
                  <v-card-text>
                    <v-row justify="space-between" no-gutters>
                      <v-col class="text-right">
                        <span class="text-h6 mr-2" v-text="this.$t('proto.' + $store.getters.availableProducts.filter(x=>x.id==current_program.objectCategoryProgramsList[0].objectCategory)[0].title)"></span>
                        <div class="text-overline  font-weight-light mr-1">{{$t("dashboard.sorting_product")}}</div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <main-metrics :md="md"/>
          </v-card>
        </v-col>

        <v-col cols="12" sm="12" :md="12-md" class="ma-0 pa-0">
          <!-- statistics -->
          <dash-statistics :md="md" v-on:md="md = $event"/>
          <!-- debug -->
          <dash-debug/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import axios from "axios";

import DashToolBar from "../components/dashboard/DashToolBar";
import DashDebug from "../components/dashboard/DashDebug";
import DashStatistics from "../components/dashboard/DashStatistics";

import MainMetrics from "../components/metrics/MainMetrics";
import MachineSchema from "../components/MachineSquemaSVG2";
import MachineSpeed from "../components/settings/MachineSpeed";

export default {
  name: "dashboard",
  components: {
    DashToolBar,
    DashDebug,
    DashStatistics,
    MainMetrics,
    MachineSchema,
    MachineSpeed
  },

  data: () => ({
    md: 7,
    show: false,
    show_problem: false,
    new_problemsList: undefined
  }),
  computed: {
    ...mapState({
      alerts: state => state.alerts,
      reply_commons: state => state.reply_commons,
      current_program: state => state.program
    }),
    machine_problems() {
      return this.$store.getters.machineProblemsTypes;
    }
  },
  watch: {
    alerts(v) {
      if (v.length) {
        this.show = v.length > 0;
      }
    },
    reply_commons(val) {
      if (val.problemsList) {
        let pl = val.problemsList;
        if (this.new_problemsList == undefined) {
          this.new_problemsList = pl;
          this.show_problem = true;
        }
        if (JSON.stringify(this.new_problemsList) !== JSON.stringify(pl)) {
          this.new_problemsList = pl;
          this.show_problem = true;
        }
      }
    }
  },
  methods: {
    ...mapActions(["set_active_program_id"]),
    calcMargin(i) {
      return i * 60 + "px";
    }
  }
};
</script>

<style lang="scss">
@import "../style/style.scss";

.card-body-icon {
  position: relative;
  z-index: 0;
  top: -7vh;
  right: -40vh;
  -webkit-transform: rotate(15deg);
  -ms-transform: rotate(15deg);
  transform: rotate(15deg);
}
.select-right {
  width: 15vh !important;
  max-width: 18vh !important;
  font-size: 12px;
}
.background-transparent {
  background: transparent !important;
}
.machine-schema {
  margin-top: 8vh !important;
  min-height: 24vh !important;
}
.alertList {
  min-height: 15vh !important;
  max-height: 15vh !important;
  overflow-y: scroll;
  background-color: gray;
  color: white;
}
</style>