<template>
  <div>
    <overlay-msg
      :show_overlay="show_overlay_loadcell"
      :color="'orange'"
      :icon="'mdi-cup-outline'"
      :title="$t('calibration.loadcell_msg')"
      :title2="$t('calibration.msg2') "
      :loading_msg="$t('calibration.loading') "
      :is_load_cell="true"
      v-on:show_overlay="show_overlay_loadcell = $event"
    />

    <overlay-msg
      :show_overlay="show_overlay_vision"
      :color="'orange'"
      :icon="'mdi-basketball'"
      :title="$t('calibration.ball_msg') "
      :is_vision="true"
      v-on:show_overlay="show_overlay_vision = $event"
    />

    <overlay-msg
      :show_overlay="summary_state == 'INTERRUPTED'"
      :title="$t('state.interrupted') "
      :title2="''"
      :loading_msg="'' "
    />

    <overlay-msg
      :show_overlay="!websocket_connected"
      :title="$t('websocket.disconnected') "
      :loading_msg="$t('websocket.reconnect') "
      :small_msg="$t('websocket.try_refresh')"
      :show_close="false"
    />
  </div>
</template>


<script>
import { mapState, mapActions } from "vuex";
import OverlayMsg from "@/components/OverlayMsg";

export default {
  name: "statesMsg",
  components: {
    OverlayMsg
  },
  data: () => ({
    force_open: true,
    show_overlay_loadcell: false,
    show_overlay_vision: false
  }),
  computed: {
    ...mapState({
      websocket_connected: state => state.ws_connected || false
    }),
    summary_state() {
      return this.$store.getters.statusSummary.state;
    }
  },
  watch: {
    summary_state(v) {
      this.$store.commit(
        "add_debug_problem",
        new Date().toLocaleString() +
          ": machine state changing to " +
          v.toLowerCase().replace("_", " ")
      );
      if (v == "VISION_CALIBRATION") {
        this.show_overlay_vision = true;
        this.show_overlay_loadcell = false;
      } else if (v == "LOADCELL_CALIBRATION") {
        this.show_overlay_loadcell = true;
        this.show_overlay_vision = false;
      } else {
        this.show_overlay_loadcell = false;
        this.show_overlay_vision = false;
      }
    }
  },
  methods: {
    force_overlay() {
      if (this.summary_state == "VISION_CALIBRATION") {
        this.show_overlay_vision = true;
      } else if (this.summary_state == "LOADCELL_CALIBRATION") {
        this.show_overlay_loadcell = true;
      }
    }
  }
};
</script>

