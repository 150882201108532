












































import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { cloneDeep } from "lodash";
import { PropType } from "vue/types/umd";
import { Plotly } from "vue-plotly";
import { Colors } from "../distribution/draggable/mix/colors";
import { ExitsUtils } from "../distribution/utils/exits_utils";

import {
  Clazz,
  Criterion,
  Program,
  DroppingRelation,
  ObjectCategoryProgram
} from "gs-proto/js/gs/proto/program_data_pb";
import { Field } from "gs-proto/js/gs/proto/categories_data_pb";
import { State } from "@/store/model";

export default Vue.extend({
  name: "Statistics",
  components: {
    Plotly
  },
  data() {
    return {
      selectedExits: [],
      data: [],
      layout: {
        colorway: new Colors().colors,
        xaxis: {
          zeroline: false,
          showline: false,
          title: {
            text: "Products",
            font: {
              size: 10,
              color: "#7f7f7f"
            }
          }
        },
        yaxis: {
          categoryorder: "array"
        },
        bargap: 0.5,
        barmode: "stack",
        paper_bgcolor: "rgba(0,0,0,0)",
        plot_bgcolor: "rgba(0,0,0,0)",
        autosize: true,
        showlegend: true,
        showgrid: true,
        legend: {
          x: 1,
          y: 1,
          orientation: "v"
        },
        margin: {
          t: 2,
          b: 30
        }
      }
    };
  },
  computed: {
    ...mapState({
      current_program: state => (state as State).program,
      machine: state => (state as State).machine,
      statistics: state => (state as State).statistics
    }),
    selectedAllExits() {
      return (
        (this as any).selectedExits.length ===
        (this as any).machine.exitsList.length
      );
    },
    selectedSomeExits() {
      return (
        (this as any).selectedExits.length > 0 &&
        !(this as any).selectedAllExits
      );
    },
    icon() {
      if ((this as any).selectedAllExits) return "mdi-close-box";
      if ((this as any).selectedSomeExits) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    }
  },
  watch: {
    machine(v) {
      (this as any).generate_data_plot();
      (this as any).selectedExits = (this as any).machine.exitsList.map(
        x => x.id
      );
    },
    statistics(v) {
      (this as any).generate_data_plot();
    },
    selectedExits(v) {
      (this as any).generate_data_plot();
    }
  },
  mounted() {
    (this as any).generate_data_plot();
    if ((this as any).machine) {
      (this as any).selectedExits = (this as any).machine.exitsList.map(
        x => x.id
      );
    }
  },
  methods: {
    format_date(d) {
      return (
        [d.getDate(), d.getMonth() + 1, d.getFullYear()].join("/") +
        " " +
        [d.getHours(), String(d.getMinutes()).padStart(2, "0")].join(":")
      );
    },
    format_timestamp(secs) {
      var t = new Date(Date.UTC(1970, 0, 1));
      t.setUTCSeconds(secs);
      return (this as any).format_date(t);
    },

    exitToText(state) {
      return this.$t("proto." + ExitsUtils.exitStateToNameArray()[state]);
    },

    toggle() {
      this.$nextTick(() => {
        if (this.selectedAllExits) {
          (this as any).selectedExits = [];
        } else {
          (this as any).selectedExits = (this as any).machine.exitsList.map(
            x => x.id
          );
        }
      });
    },

    generate_data_plot() {
      if (this.current_program == undefined){
        return 0;
      }
      let data = [] as any;
      if (
        (this as any).statistics &&
        (this as any).statistics.hasOwnProperty("exitStatisticsList")
      ) {
        let exitStatisticsList = (this as any).statistics.exitStatisticsList;
        let exits = exitStatisticsList.map(x => " Exit " + x.exitId + " ");
        let selectedExits = (this as any).selectedExits;
        let selectedExitsText = (this as any).selectedExits.map(
          x => " Exit " + x + " "
        );
        let x_values = [] as any;
        let class_list = cloneDeep(
          (this as any).current_program.objectCategoryProgramsList[0]
            .classesList
        );

        class_list.push({
          id: 0,
          name: (this as any).$t("distribution.remaining")
        });
        
        class_list.push({
          id: -1,
          name: (this as any).$t("distribution.invalid")
        });

        class_list.forEach(clazz => {
          x_values = exitStatisticsList.map(x =>
            x.classPiecesList
              .filter(p => p.classId == clazz.id)
              .map(k => k.pieces)
          );

          let final_exits = [] as any;
          let final_X = [] as any;
          let idx = 0;

          x_values.forEach(x => {
            if (x.length != 0) {
              if (selectedExits.includes(idx + 1)) {
                final_X.push(x[0]);
                final_exits.push(exits[idx]);
              }
            }
            idx += 1;
          });

          data.push({
            y: final_exits,
            x: final_X,
            name: clazz.name,
            type: "bar",
            orientation: "h"
          });
        });

        let exits_with_data = Array.prototype.concat(...data.map(x => x["y"]));
        let exits_without_data = exits.filter(
          x => !exits_with_data.includes(x) && selectedExitsText.includes(x)
        );
        data[data.length - 1]["y"] = data[data.length - 1]["y"].concat(
          exits_without_data
        );
        data[data.length - 1]["x"] = data[data.length - 1]["x"].concat(
          new Array(exits_without_data.length).fill(0)
        );
        (this as any).layout["yaxis"]["categoryarray"] = exits
          .filter(x => selectedExitsText.includes(x))
          .reverse();
        (this as any).data = data;
      }
    }
  }
});
