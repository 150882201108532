







































































































import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { cloneDeep } from "lodash";

import {
  LanesetConfigChangeRequest,
  CupObject
} from "gs-proto/js/gs/proto/ui_data_pb";

import { ObjectProfile } from "gs-proto/js/gs/proto/object_data_pb";

import { VisionConfiguration } from "gs-proto/js/gs/proto/configs_data_pb";
import { State } from "@/store/model";

import LaneDialog from "./LaneDialog.vue";
import ActiveCup from "./ActiveCup.vue";

export default {
  data() {
    return {
      dialog: false,
      active: -1 as Number,
      cup_active: undefined
    };
  },
  components: {
    LaneDialog,
    ActiveCup
  },
  computed: {
    ...mapState({
      cup_objects: state =>
        (state as State).cup_objects as CupObject.AsObject[],
      current_program: state => (state as State).program
    })
  },

  methods: {
    is_active(cup) {
      return (this as any).active == (this as any).get_active_id(cup);
    },
    get_active_id(cup) {
      return (
        cup.cupId +
        "_" +
        cup.exitId +
        "_" +
        cup.objectProfile.laneId +
        "_" +
        cup.objectProfile.referencePointTimestamp.nanos
      );
    },
    activate(cup, s) {
      (this as any).active = (this as any).get_active_id(cup);
      (this as any).cup_active = cloneDeep(cup);
    },
    get_class_name(classId) {
      if (classId == 0) {
        return (this as any).$t("distribution.remaining");
      }
      if (classId == -1) {
        return (this as any).$t("distribution.invalid");
      }
      try {
        let c_list = (this as any).current_program.objectCategoryProgramsList[0].classesList.filter(
          x => x.id == classId
        );
        if (c_list.length > 0) {
          return c_list[0].name;
        }
        return classId;
      } catch {
        return classId;
      }
    },

    get_object_title(objectCategory) {
      let o_list = (this as any).$store.getters.availableProducts.filter(
        x => x.id == objectCategory
      );
      if (o_list.length > 0) {
        return (
          objectCategory + "-" + (this as any).$t("proto." + o_list[0].title)
        );
      }
      return objectCategory;
    }
  }
};
