






























import Vue from "vue";
import { cloneDeep } from "lodash";
import { mapState, mapActions } from "vuex";

import { State } from "@/store/model";
import { Program } from "gs-proto/js/gs/proto/program_data_pb";

import { MappingFunction } from "@/store/proto_utils/mapping";

export default Vue.extend({
  name: "cloneDialog",
  data() {
    return {
      dialog: false,
      valid: false,
      new_name: "new program",
      rules: {
        required: value => !!value || this.$t("distribution.rule_required"),
        counter: value => value.length <= 40 || this.$t("program.rule_max"),
        symbol: value => {
          const pattern = /^[a-zA-Z0-9 ]*$/;
          return pattern.test(value) || this.$t("distribution.rule_invalid");
        },
        unique(programs, msg) {
          return v => !programs.map(x => x.title).includes(v) || msg;
        }
      }
    };
  },
  props: {
    snackbar_clone: {
      type: Boolean,
      default: false
    },
    tmp_clone_program: {
      type: Object
    }
  },

  computed: {
    ...mapState({
      programs: state => (state as State).available_programs,
      current_program: state => (state as State).program 
    })
  },
  watch: {
    snackbar_clone(v) {
      this.dialog = v;
    },
    dialog(v) {
      this.new_name = this.current_program ? this.current_program.title+ " COPY" : "";
      this.$emit("snackbar_clone", v);
    }
  },
  methods: {
    ...mapActions(["create_or_update_program"]),

    clone() {
      let updated_program: Program.AsObject = cloneDeep(this.current_program);
      updated_program.id = 0;
      updated_program.title = this.new_name;
      let _program: Program = MappingFunction.programToProto(updated_program);
      this.create_or_update_program(_program);

      this.dialog = false;
    }
  }
});
