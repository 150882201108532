































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { State } from "@/store/model";

import SettingsCard from "./settings/SettingsDialog.vue";
import ClassificationCard from "./classes/ClassDialog.vue";
import DistributionCard from "./distribution/DistributionDialog.vue";

export default {
  components: {
    SettingsCard,
    ClassificationCard,
    DistributionCard
  },
  data() {
    return {
      active: -1 as Number,
      menu_options: {
        0: "settings",
        1: "classification",
        2: "distribution"
      }
    };
  },

  computed: {
    ...mapState({
      current_program: state => (state as State).program,
      websocket_connected: state => (state as State).ws_connected,
    })
  },

  methods: {
    set_active(i) {
      (this as any).active = i;
    },

    open_active(i) {
      if ((this as any).websocket_connected) {  
        switch (i) {
          case 0:
            let set_c: any = (this as any).$refs.settingsComponent as Vue;
            if (set_c) {
              set_c.dialog = true;
            }
            break;

          case 1:
            let class_c: any = (this as any).$refs.classificationComponent as Vue;
            if (class_c && (this as any).current_program) {
              class_c.dialog = true;
            }
            break;

          case 2:
            let distribution_c: any = (this as any).$refs
              .distributionComponent as Vue;
            if (distribution_c && (this as any).current_program)  {
              distribution_c.dialog = true;
            }
            break;
        }
      }
    }
  }
};
