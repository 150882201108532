

































import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { cloneDeep } from "lodash";
import {
  fields_meta,
  ObjectProfileMetaType
} from "gs-proto/js/gs/proto/categories_data_meta";
import {
  LanesetConfigChangeRequest,
  CupObject
} from "gs-proto/js/gs/proto/ui_data_pb";

import { State } from "@/store/model";
import { Filter } from "gs-proto/js/gs/proto/program_data_pb";

export default {
  props: {
    colorsList: {
      type: Array
    },
    cup_active: {
      type: Object
    },
    cupClassId: {}
  },
  data() {
    return {
      fields_meta
    };
  },

  computed: {
    ...mapState({
      current_program: state => (state as State).program
    }),
    filters() {
      return (this as any).current_program.objectCategoryProgramsList[0]
        .filtersList;
    },

    avg_hue_range() {
      let criteria = (this as any).get_class_criteria((this as any).cupClassId);
      for (let c in criteria) {
        if (criteria[c]["field"] == 40001) {
          // TODO
          return [
            criteria[c]["colorRange"]["minHue"],
            criteria[c]["colorRange"]["maxHue"]
          ];
        }
      }
      return [0, 0];
    }
  },

  methods: {
    fulfilCriteria(filter, color, classId) {
      if (classId == 0) {
        return false;
      }

      let criteriaList = (this as any).current_program.objectCategoryProgramsList[0].classesList.filter(
        x => x.id == classId
      )[0].criteraList;

      let criteria = criteriaList.filter(c => c.field == filter.field)[0];

      if (criteria.colorRange) {
        let h = parseFloat((this as any).HueSVtoHueSL(color));
        let min_h = criteria.colorRange.minHue;
        let max_h = criteria.colorRange.maxHue;
        if (h >= min_h && h <= max_h) {
          return true;
        }
      }

      if (criteria.colorSurfaceRange) {
        let s = color.surface * 100;
        let min_s = criteria.colorSurfaceRange.minSurface;
        let max_s = criteria.colorSurfaceRange.maxSurface;
        if (s >= min_s && s <= max_s) {
          return true;
        }
      }
      return false;
    },
    insideFilter(color) {
      let h = (this as any).HueSVtoHueSL(color);
      let l = (this as any).ValuetoLigth(color);

      let point = [h, l];
      let filters_inside = [] as any;
      let inside_polygon = (this as any).insidePolygon;

      (this as any).filters.forEach(filter => {
        let found = false;
        if (filter.field != 40001) {
          //AVOID AVERAGE FILTER
          filter.polygonsList.forEach(polygon => {
            if (!found) {
              let points = polygon.pointsList.map(el => [el["x"], el["y"]]);
              if (inside_polygon(point, points)) {
                // check percentage
                filters_inside.push(filter);
                found = true;
              }
            }
          });
        }
        found = false;
      });

      return filters_inside;
    },
    insidePolygon(point, polygon) {
      // ray-casting algorithm
      var x = point[0] / 100,
        y = point[1] / 100;

      var inside = false;
      for (var i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        var xi = polygon[i][0],
          yi = polygon[i][1];
        var xj = polygon[j][0],
          yj = polygon[j][1];

        var intersect =
          yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
        if (intersect) inside = !inside;
      }
      return inside;
    },

    hsv_to_hsl(h, s, v) {
      // both hsv and hsl values are in [0, 1]
      var l = ((2 - s) * v) / 2;
      if (l != 0) {
        if (l == 1) {
          s = 0;
        } else if (l < 0.5) {
          s = (s * v) / (l * 2);
        } else {
          s = (s * v) / (2 - l * 2);
        }
      }
      return [h, s, l];
    },

    renderOneColor(color) {
      let [h, s, l] = (this as any).hsv_to_hsl(
        color.hue / 180,
        color.saturation / 255,
        color.value / 255
      );
      let style =
        "background:  hsl(" + h * 360 + "," + s * 100 + "%, " + l * 100 + "%)";
      return style;
    },

    HueSVtoHueSL(color) {
      let h = (this as any).hsv_to_hsl(
        color.hue / 180,
        color.saturation / 255,
        color.value / 255
      )[0];
      if (h < 0.5) {
        h += 0.5;
      } else {
        h -= 0.5;
      }
      return (h * 100).toFixed(1);
    },
    SaturationVtoSaturationL(color) {
      let [h, s, l] = (this as any).hsv_to_hsl(
        color.hue / 180,
        color.saturation / 255,
        color.value / 255
      );
      return (s * 100).toFixed(1);
    },
    ValuetoLigth(color) {
      let [h, s, l] = (this as any).hsv_to_hsl(
        (color.hue * 2) / 360,
        color.saturation / 255,
        color.value / 255
      );
      return (l * 100).toFixed(1);
    },

    get_class_criteria(classId) {
      if ((this as any).current_program.objectCategoryProgramsList.length > 0) {
        let c_list = (this as any).current_program.objectCategoryProgramsList[0].classesList.filter(
          x => x.id == classId
        );
        if (c_list.length > 0) {
          return c_list[0].criteraList;
        }
      }
      return [];
    }
  }
};
