


































import { mapState, mapActions } from "vuex";
import {
  LanesetConfigChangeRequest,
  CupObject
} from "gs-proto/js/gs/proto/ui_data_pb";
import { State } from "@/store/model";

export default {
  name: "dashboardDebug",

  data() {
    return {};
  },

  computed: {
    ...mapState({
      websocket_connected: state => (state as State).ws_connected || false,
      available_programs: state => (state as State).available_programs || [],
      current_program: state => (state as State).program,
      machine: state => (state as State).machine
      // cup_objects: state => (state as State).cup_objects as CupObject.AsObject[]
      // alerts: state => state.alerts,
    })
  }
};
