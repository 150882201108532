
















import Vue from "vue";
import { mapState, mapActions } from "vuex";

import { State } from "@/store/model";

import CupImage from "./activeCup/CupImage.vue";
import CupFilledValues from "./activeCup/CupFilledValues.vue";
import CupData from "./activeCup/CupData.vue";

export default {
  data() {
    return {};
  },
  props: {
    cup_active: {}
  },
  components: {
    CupImage,
    CupFilledValues,
    CupData
  },
  computed: {
    ...mapState({
      current_program: state => (state as State).program
    })
  }
};
