







































































import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { cloneDeep } from "lodash";

import { CupObject } from "gs-proto/js/gs/proto/ui_data_pb";
import {
  fields_meta,
  ObjectProfileMetaType
} from "gs-proto/js/gs/proto/categories_data_meta";
import { ObjectProfile } from "gs-proto/js/gs/proto/object_data_pb";
import { VisionConfiguration } from "gs-proto/js/gs/proto/configs_data_pb";
import { State } from "@/store/model";


export default {
  data() {
    return {
      fields_meta,
    };
  },
  props: {
    cup_active: {}
  },
 
  computed: {
    ...mapState({
      current_program: state => (state as State).program
    })
  },

  methods: {
    get_status(i) {
      switch (i.status) {
        case ObjectProfile.Status.MEASURED:
          return (this as any).$t("proto.status_measured");
        case ObjectProfile.Status.FAILED_MEASUREMENT:
          return (this as any).$t("proto.status_failed_measurement");
        case ObjectProfile.Status.PARTIALLY_MEASURED:
          return (this as any).$t("proto.status_partially_measured");
        case ObjectProfile.Status.UNACCEPTABLE_OBJECT:
          return (this as any).$t("proto.status_unacceptable_object");
      }
      return (this as any).$t("proto.status_not_found");
    },

    get_list_by_classId(classId) {
      let c_list = (this as any).current_program.objectCategoryProgramsList[0].classesList.filter(
        x => x.id == classId
      );
      return c_list;
    },

    get_class_name(classId) {
      if (classId == 0) {
        return (this as any).$t("distribution.remaining");
      }
      if (classId == -1) {
        return (this as any).$t("distribution.invalid");
      }
      let c_list = (this as any).get_list_by_classId(classId);
      if (c_list.length > 0) {
        return c_list[0].name;
      }
      return classId;
    },
    get_class_criteria(classId) {
      if ((this as any).current_program.objectCategoryProgramsList.length > 0) {
        let c_list = (this as any).get_list_by_classId(classId);
        if (c_list.length > 0) {
          return c_list[0].criteraList;
        }
      }
      return [];
    },

    format_timestamp(secs) {
      let t = new Date(Date.UTC(1970, 0, 1)); // Epoch
      t.setUTCSeconds(secs);
      return t;
    },

    get_field_name(field) {
      let f_list = (this as any).$store.getters.availableFields.filter(
        x => x.id == field
      );
      if (f_list.length > 0) {
        return field + "-" + (this as any).$t("proto." + f_list[0].title);
      }
      return field;
    }
  }
};
