



































































import { mapState, mapActions } from "vuex";

import TimelineLog from "./TimelineLog.vue";
import LanesetsCalibration from "@/components/dashboard/states/LanesetsCalibration.vue";

import { State } from "@/store/model";

export default {
  name: "overlayMsg",

  components: {
    TimelineLog,
    LanesetsCalibration
  },

  props: {
    opacity: {
      type: Number,
      default: 0.75
    },
    color: {
      type: String,
      default: "error"
    },
    icon: {
      type: String,
      default: "mdi-alert"
    },
    show_overlay: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    title2: {
      type: String,
      default: ""
    },
    loading_msg: {
      type: String,
      default: ""
    },
    small_msg: {
      type: String,
      default: ""
    },
    show_close: {
      type: Boolean,
      default: true
    },
    is_load_cell: {
      type: Boolean,
      default: false
    },
    is_vision: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    show_over: false
  }),
  computed: {
    ...mapState({
      machine: state => (state as State).machine
    })
  },

  watch: {
    show_overlay(v) {
      (this as any).show_over = v;
    }
  },

  methods: {
    ...mapActions(["start_calibration"]),
    close() {
      (this as any).show_over = false;
      (this as any).$emit("show_overlay", false);
    },
    start_calibrating() {
      let debug_text =
        new Date().toLocaleString() + ": requesting new calibration start ";
      (this as any).$store.commit("add_debug_problem", debug_text);
      (this as any).start_calibration();
      (this as any).is_load_cell = false;
      // (this as any).show_close = true;
    }
  }
};
