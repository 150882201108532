























import Vue from "vue";
import { cloneDeep } from "lodash";
import { mapState, mapActions } from "vuex";

import { State } from "@/store/model";
import { Program } from "gs-proto/js/gs/proto/program_data_pb";

export default Vue.extend({
  name: "deleteSnackbar",
  data() {
    return {
      snackbar: false
    };
  },
  props: {
    snackbar_remove: {
      type: Boolean,
      default: false
    },
    tmp_remove_program: {
      type: Object
    }
  },

  computed: {
    ...mapState({
      current_program: state => (state as State).program
    })
  },
  watch: {
    snackbar_remove(v) {
      this.snackbar = v;
    },
    snackbar(v) {
      this.$emit("snackbar", v);
    }
  },
  methods: {
    ...mapActions(["delete_program"]),

    remove() {
      // TODO check program can be deleted
      this.delete_program(this.tmp_remove_program.id);
      this.snackbar = false;
    }
  }
});
