<template>
  <v-card class="pr-4 pl-4 pt-2 pb-3 pt ml-2 mr-2" elevation="0">
    <div class="projects-section-header">
      <p style="font-size:18px!important">
        <v-icon small v-if="internal_md==7" @click="internal_md=2">mdi-chevron-left</v-icon>
        <v-icon small v-else @click="internal_md=7">mdi-chevron-right</v-icon>
        <b class="ml-3">{{$t("dashboard.statistics")}}</b>
      </p>
      <p>
        <v-btn
          small
          outlined
          @click="reset_statistics_request()"
          color="primary"
          dark
        >{{$t("dashboard.reset_statistics")}}</v-btn>
      </p>
    </div>

    <div>
      <statistics/>
    </div>
  </v-card>
</template>


<script>
import vm from "vue";
import { mapState, mapActions } from "vuex";
import axios from "axios";

import Statistics from "../metrics/Statistics";

export default {
  name: "dashboardStatistics",
  components: {
    Statistics
  },
  data: () => ({
    internal_md: 7
  }),
  props: {
    md: {
      type: Number,
      default: 7
    }
  },
  computed: {
    ...mapState({
      state: state => state
    })
  },
  mounted() {
    this.internal_md = this.md;
  },
  watch: {
    internal_md(v) {
      this.$emit("md", v);
    }
  },
  methods: {
    ...mapActions(["set_active_program_id", "reset_statistics", "clear_results"]),

    reset_statistics_request() {
      this.reset_statistics();
      this.clear_results();
    }

  }
};
</script>
