











































import { mapState, mapActions } from "vuex";
import { State } from "@/store/model";

export default {
  name: "lanesetsCalibration",

  computed: {
    ...mapState({
      machine: state => (state as State).machine
    })
  }
};
